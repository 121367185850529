<template>
  <a-modal
    ref="modalRef"
    class="modalRef"
    :title="noticeTitle"
    :visible="open"
    centered
    :wrap-style="{ overflow: 'hidden' }"
    @cancel="handleClose"
    @ok="handleInvite"
  >
    <div class="cnetent">
      <div>{{ noticeContent }}</div>
      <div class="choose">
        已选 <span class="number">&nbsp{{ num }}&nbsp</span> 条
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { defineProps, defineEmits, ref } from 'vue';
const prop = defineProps({
  open: { type: Boolean, default: true },
  noticeTitle: {
    type: String,
    default: '',
  },
  noticeContent: {
    type: String,
    default: '',
  },
  num: {
    type: Number,
    default: 0,
  },
});
const emits = defineEmits(['update:open', 'handleInvite']);
const handleClose = () => {
  emits('update:open', false);
};
const handleInvite = () => {
  emits('handleInvite');
};
</script>
<style lang="less" scoped>
.cnetent {
  font-size: 14px;
  margin-left: 35px;
  .choose {
    margin-top: 20px;
    .number {
      color: #4d7cff;
      font-size: 16px;
    }
  }
}
</style>
