<template>
  <a-modal
    :visible="editVisible"
    :title="editId ? '编辑' : '新建'"
    @cancel="handleClose"
    :footer="null"
  >
    <a-form
      :model="formState"
      :label-col="{
        span: 6,
      }"
      :wrapper-col="{
        span: 14,
      }"
    >
      <a-form-item label="编码">
        <a-input
          v-model:value="formState.contactCode"
          placeholder="如不填写会由系统自动生成"
        />
      </a-form-item>
      <a-form-item
        label="名称"
        :rules="[
          {
            required: true,
          },
        ]"
      >
        <a-input v-model:value="formState.contactName" />
      </a-form-item>
      <a-form-item
        label="联系电话"
        :rules="[
          {
            required: true,
          },
        ]"
      >
        <a-input v-model:value="formState.phone" />
      </a-form-item>
      <a-form-item
        label="身份证号"
        :rules="[
          {
            required: true,
          },
        ]"
      >
        <a-input
          v-model:value="formState.idCard"
          :disabled="editId ? true : false"
        />
      </a-form-item>
      <a-form-item label="性别">
        <a-radio-group v-model:value="formState.gender">
          <span v-for="(item, index) in genderList" :key="index">
            <a-radio :value="item.id">
              {{ item.value }}
            </a-radio>
          </span>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        label="触点类型"
        :rules="[
          {
            required: true,
          },
        ]"
      >
        <a-radio-group v-model:value="formState.contactType">
          <span v-for="(item, index) in contactTypeList" :key="index">
            <a-radio :value="item.id">
              {{ item.value }}
            </a-radio>
          </span>
        </a-radio-group>
      </a-form-item>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          padding-bottom: 30px;
        "
      >
        <a-button type="primary" @click="onSubmit">提交</a-button>
      </div>
    </a-form>
  </a-modal>
</template>
<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits, watch } from 'vue';
import {
  contactSave,
  contactGetEnum,
  contactGet,
  contactUpdate,
} from '@/api/contact.js';
import { message } from 'ant-design-vue';
// export default defineComponent({
//   setup() {
const prop = defineProps({
  editVisible: Boolean,
  editId: Number,
  contactTypeList: Array,
});
const emits = defineEmits([
  'update:editVisible',
  'handleQuery',
  'resetEditId',
  'handleQueryStatistics',
]);
const formState = ref({
  contactId: undefined,
  contactName: undefined,
  contactCode: undefined,
  phone: undefined,
  idCard: undefined,
  gender: undefined,
  contactType: 0,
});
const resetFormState = () => {
  formState.value = {
    contactId: undefined,
    contactName: undefined,
    contactCode: undefined,
    phone: undefined,
    idCard: undefined,
    gender: undefined,
    contactType: 0,
  };
};
const onSubmit = () => {
  if (formState.value.contactId) {
    contactUpdate(formState.value).then((res) => {
      if (res.data.success) {
        message.success(res.data.data);
        resetFormState();
        emits('update:editVisible', false);
        emits('handleQuery');
        emits('handleQueryStatistics');
      }
    });
  } else {
    contactSave(formState.value).then((res) => {
      if (res.data.success) {
        message.success(res.data.data);
        resetFormState();
        emits('update:editVisible', false);
        emits('handleQuery');
        emits('handleQueryStatistics');
      }
    });
  }
};

const handleClose = (e) => {
  emits('update:editVisible', false);
  resetFormState();
  emits('resetEditId');
};
const genderList = ref();
const getGender = async () => {
  await contactGetEnum('Gender').then((res) => {
    if (res.data.success) {
      genderList.value = res.data.data;
    }
  });
};

const fetchUserDetails = async () => {
  emits('update:editVisible', true);
  await contactGet(prop.editId).then((res) => {
    if (res.data.success) {
      formState.value = {
        contactId: res.data.data.contactId,
        contactName: res.data.data.contactName,
        contactCode: res.data.data.contactCode,
        phone: res.data.data.phone,
        idCard: res.data.data.idCard,
        gender: res.data.data.gender,
        contactType: res.data.data.contactType,
      };
    }
  });
};

onMounted(() => {
  getGender();
});
// 监听props.userId的变化，重新获取用户详情
watch(
  () => prop.editId,
  (newVal) => {
    if (newVal) {
      fetchUserDetails();
    }
  }
);

// 在组件挂载时获取用户详情
onMounted(() => {
  if (prop.editId) {
    fetchUserDetails();
  }
});
</script>
