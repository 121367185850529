import request from '../utils/request.js';
//如果走app相关的页面就在所有的请求路径上加上app
let baseUrl = "/api/executor/v1";

//获取触点列表信息
export function contactList(params) {
    return request().get(`${baseUrl}/contact/list`, { params })
}

// 触点新建
export function contactSave(params) {
    return request().post(`${baseUrl}/contact/save`, {
        ...params,
    })
}

// 触点修改
export function contactUpdate(params) {
    return request().post(`${baseUrl}/contact/update`, {
        ...params,
    })
}

// 获取租户客户列表
export function contactCustomerList(params) {
    return request().get(`${baseUrl}/contact/customerList`, { params })
}

// 触点详情查询
export function contactGet(params) {
    return request().get(`${baseUrl}/contact/get?contactId=${params}`)
}

// 触点统计
export function contactStatistics(params) {
    return request().get(`${baseUrl}/contact/statistics`, {
        ...params,
    })
}

// 触点列表导出
export function contactExport(params) {
    return request().get(`${baseUrl}/contact/export`, {
        ...params,
    })
}

// 发起关联关系
export function contactSendAssociation(params) {
    return request().get(`${baseUrl}/contact/sendAssociation?contactId=${params}`)
}

// 发送邀请消息
export function contactSendInvite(data) {
    return request().post(`${baseUrl}/contact/sendInvite`, data)
}

// 解除关联关系
export function contactAssociationUnband(params) {
    return request().get(`${baseUrl}/contact/associationUnband?contactId=${params}`)
}

// 合同新建
export function contactContracSave(params) {
    return request().post(`${baseUrl}/contact/contract/save`, {
        ...params,
    })
}

// 合同列表
export function contactContractList(params) {
    return request().get(`${baseUrl}/contact/contract/list`, { params })
}

// 终止合同
export function contactContractDischarge(params) {
    return request().get(`${baseUrl}/contact/contract/discharge?contractId=${params}`)
}

// 删除合同
export function contactContractDel(params) {
    return request().get(`${baseUrl}/contact/contract/del?contractId=${params}`)
}

// 解密手机号
export function contractDecryptPhone(params) {
    return request().get(`${baseUrl}/contact/decryptPhone?phoneKey=${params}`)
}


//获取相关枚举类
export function contactGetEnum(params) {
    return request().get(`${baseUrl}/contact/getEnum?enumKey=${params}`)
}

export function getUnJoinContactCount(params) {
    return request().get(`${baseUrl}/contact/getUnJoinContactCount`, {
        ...params,
    })
}

export function syncCrm() {
    return request().get(`${baseUrl}/contact/contract/syncCrm`)
}

// 编辑合同
export function contractUpdate(params) {
    return request().post(`${baseUrl}/contact/contract/update`, {
        ...params,
    })
}
// 导入相关
export function getTemplate() {
    return request().get(`${baseUrl}/contact/getTemplate`)
}

export function getStatus() {
    return request().get(`${baseUrl}/contact/getStatus`)
}

export function reload() {
    return request().get(`${baseUrl}/contact/reload`)
}

export function getError() {
    return `${baseUrl}/contact/getError?fileUrl=`
}

export function contactImport(data) {
    return request().post(`${baseUrl}/contact/import`, data)
}

//上传模板
export function templateUpload() {
    return `${baseUrl}/contact/upload`
}
