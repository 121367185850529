<template>
  <a-modal
    :visible="contractVisible"
    title="合同管理"
    @cancel="handleClose"
    width="1500px"
    :footer="null"
  >
    <a-button type="primary" @click="addContract" style="margin-left: 20px"
      >添加合同</a-button
    >
    <div style="padding: 20px">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        style="margin-top: 8px"
        :pagination="false"
        :rowKey="contractId"
      >
        <template #customerName="{ text, record }">
          <div>
            <a-select
              v-if="editableData[record.contractId]"
              v-model:value="customerSelectEdit"
              :filter-option="false"
              show-search
              placeholder="请选择"
              style="width: 200px"
              label-in-value
              :options="customerList"
              @change="handleChange"
              @search="getContactCustomerList"
            >
              <a-select-option
                v-for="item in customerList"
                :key="item.value"
                :value="item.value"
                >{{ item.label }}</a-select-option
              >
            </a-select>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template #contractStatusStr="{ text, record }">
          <div>
            <a-radio-group
              v-model:value="formStateEdit.contractStatus"
              v-if="editableData[record.contractId]"
            >
              <span v-for="(item, index) in contractStatusList" :key="index">
                <a-radio :value="item.id">
                  {{ item.value }}
                </a-radio>
              </span>
            </a-radio-group>
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template #validDate="{ record }">
          <div>
            <a-range-picker
              v-if="editableData[record.contractId]"
              v-model:value="contractDateEdit"
              :format="dateFormat"
            />
            <template v-else>
              {{
                record.startDate
                  ? record.endDate
                    ? `${record.startDate}-${record.endDate}`
                    : `${record.startDate}-无限期`
                  : '-'
              }}
            </template>
          </div>
        </template>

        <template #operation="{ record }">
          <span v-if="editableData[record.contractId]">
            <a-button type="link" @click="save(record)">保存</a-button>
            <a-button
              type="link"
              style="margin-left: 10px"
              @click="cancel(record.contractId)"
              >取消</a-button
            >
          </span>
          <span v-else>
            <a-button
              type="link"
              v-if="
                record.contractType === 0 &&
                '使用中' == record.contractUseStatusStr
              "
              @click="edit(record.contractId)"
              >编辑</a-button
            >
          </span>
          <!-- 按钮显示状态逻辑
          {{ record.contractType === 0 已结束/已终止状态   操作:删除
                                      使用中状态 操作:编辑、删除、终止
syncEnabled && record.contractType === 1 无
!syncEnabled && record.contractType === 1 使用中 操作 删除、终止
                                          已结束/已终止 操作:删除
          }} -->
          <a-popconfirm
            title="确定要删除这个合同吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="getcontactContractDel(record.contractId)"
          >
            <a-button
              type="link"
              style="margin-left: 10px"
              v-if="
                record.contractType === 0 ||
                (!syncEnabled && record.contractType === 1)
              "
              >删除</a-button
            >
          </a-popconfirm>
          <a-popconfirm
            title="确定要终止这个合同吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="getcontactContractDischarge(record.contractId)"
          >
            <a-button
              type="link"
              style="margin-left: 10px"
              v-if="
                '使用中' === record.contractUseStatusStr &&
                (record.contractType === 0 ||
                  (!syncEnabled && record.contractType === 1))
              "
              >终止</a-button
            >
          </a-popconfirm>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          v-model:current="queryParam.pageNum"
          v-model:pageSize="queryParam.pageSize"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="getContractList"
        />
      </div>
    </div>
  </a-modal>
  <a-modal
    :visible="addVisible"
    title="添加合同"
    @cancel="handleClose1"
    :footer="null"
  >
    <a-form
      :model="formState"
      :label-col="{
        span: 6,
      }"
      :wrapper-col="{
        span: 14,
      }"
    >
      <a-form-item label="合同服务对象">
        <a-select
          v-model:value="customerSelect"
          show-search
          placeholder="请选择"
          style="width: 200px"
          :options="customerList"
          :filter-option="false"
          @search="getContactCustomerList"
          @change="handleChange"
        >
        </a-select>
      </a-form-item>

      <a-form-item label="合同状态">
        <a-radio-group v-model:value="formState.contractStatus">
          <span v-for="(item, index) in contractStatusList" :key="index">
            <a-radio :value="item.id">
              {{ item.value }}
            </a-radio>
          </span>
        </a-radio-group>
      </a-form-item>
      <a-form-item label="合同有效期">
        <a-range-picker v-model:value="contractDate" />
      </a-form-item>

      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          padding-bottom: 30px;
        "
      >
        <a-button type="primary" @click="onSubmit">提交</a-button>
      </div>
    </a-form>
  </a-modal>
</template>
<script setup>
import { ref, reactive, onMounted, defineProps, defineEmits, watch } from 'vue';
import {
  contactContractList,
  contactGetEnum,
  contactCustomerList,
  contactContracSave,
  contactContractDel,
  contactContractDischarge,
  contractUpdate,
} from '@/api/contact.js';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment';

const prop = defineProps({
  contractVisible: Boolean,
  contractListId: Number,
  syncEnabled: { type: Boolean, default: false },
});

const handleChange = (value) => {
  console.log(`selected ${customerSelect.value}`, customerSelect.value);
};
const dateFormat = 'YYYY-MM-DD';
const customerSelect = ref();
const contractDate = ref();
const formState = ref({
  contactId: undefined,
  contactName: undefined,
  contactCode: undefined,
  contractStatus: undefined,
  startDate: undefined,
  endDate: undefined,
});
const customerSelectEdit = ref();
const contractDateEdit = ref();
const formStateEdit = ref({
  contactId: undefined,
  contactName: undefined,
  contactCode: undefined,
  contractStatus: undefined,
  startDate: undefined,
  endDate: undefined,
});
const contractStatusList = ref([]);
const total = ref();
const customerList = ref([]);
const customerMap = ref({});

const dataSource = ref([]);
const columns = [
  {
    title: '合同服务对象',
    dataIndex: 'customerName',
    key: 'customerName',
    slots: { customRender: 'customerName' },
    width: 200,
  },
  {
    title: '合同状态',
    dataIndex: 'contractStatusStr',
    key: 'contractStatusStr',
    slots: { customRender: 'contractStatusStr' },
    width: 100,
  },
  {
    title: '合同有效期',
    dataIndex: 'validDate',
    key: 'validDate',
    slots: { customRender: 'validDate' },
    width: 200,
  },
  {
    title: '合同更新时间',
    dataIndex: 'updatedAtStr',
    key: 'updatedAtStr',
    width: 150,
  },
  {
    title: '应用状态',
    dataIndex: 'contractUseStatusStr',
    key: 'contractUseStatusStr',
    width: 100,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    slots: { customRender: 'operation' },
    align: 'center',
    width: 200,
  },
];
// const disabledDate = (current) => {
//   return current && current < moment().endOf('day');
// };
const queryParam = ref({
  pageSize: 10,
  pageNum: 1,
  contactId: undefined,
});
const editableData = reactive({});
const edit = (key) => {
  editableData[key] = JSON.parse(
    JSON.stringify(
      dataSource.value.filter((item) => key === item.contractId)[0]
    )
  );
  console.log('editableData[key]', editableData[key]);
  customerSelectEdit.value = {
    value: editableData[key].customerCode,
    label: editableData[key].customerName,
  };
  contractDateEdit.value = [
    moment(editableData[key].startDate, dateFormat),
    moment(editableData[key].endDate, dateFormat),
  ];
  formStateEdit.value = {
    contactId: editableData[key].contactId,
    customerName: editableData[key].customerName,
    customerCode: editableData[key].customerCode,
    contractStatus: editableData[key].contractStatus,
    startDate: editableData[key].startDate,
    endDate: editableData[key].endDate,
  };
};
const save = async (record) => {
  if (contractDateEdit.value) {
    formStateEdit.value.startDate = dayjs(contractDateEdit.value[0]).format(
      'YYYY-MM-DD'
    );
    formStateEdit.value.endDate = dayjs(contractDateEdit.value[1]).format(
      'YYYY-MM-DD'
    );
  } else {
    formStateEdit.value.startDate = '';
    formStateEdit.value.endDate = '';
  }
  if (customerSelectEdit.value) {
    const ss = customerMap.value[customerSelectEdit.value.value];
    formStateEdit.value.customerName = ss.customerName;
    formStateEdit.value.customerTenCode = ss.customerTenCode;
    formStateEdit.value.customerCode = customerSelectEdit.value.value;
  }

  formStateEdit.value.contractId = record.contractId;
  await contractUpdate(formStateEdit.value).then((res) => {
    if (res.data.success) {
      message.success('修改合同成功');
      delete editableData[record.contractId];
      addVisible.value = false;
      resetFormState1();
      resetFormState();
      getContractList();
    }
  });
};
const cancel = (key) => {
  delete editableData[key];
  resetFormState1();
  resetFormState();
};
const addVisible = ref(false);
const emits = defineEmits(['update:contractVisible', 'handleQuery']);
const resetFormState = () => {
  formState.value = {
    contactId: prop.contractListId,
    contactName: undefined,
    contactCode: undefined,
    contractStatus: undefined,
    startDate: undefined,
    endDate: undefined,
  };
  contractDate.value = undefined;
  customerSelect.value = undefined;
};
const resetFormState1 = () => {
  formStateEdit.value = {
    contactId: prop.contractListId,
    contactName: undefined,
    contactCode: undefined,
    contractStatus: undefined,
    startDate: undefined,
    endDate: undefined,
  };
  contractDate.value = undefined;
  customerSelect.value = undefined;
};
const onSubmit = async () => {
  if (contractDate.value) {
    formState.value.startDate = dayjs(contractDate.value[0]).format(
      'YYYY-MM-DD'
    );
    formState.value.endDate = dayjs(contractDate.value[1]).format('YYYY-MM-DD');
  } else {
    formState.value.startDate = '';
    formState.value.endDate = '';
  }
  if (customerSelect.value) {
    const ss = customerMap.value[customerSelect.value];
    console.log('ss', ss);
    formState.value.customerName = ss.customerName;
    formState.value.customerTenCode = ss.customerTenCode;
    formState.value.customerCode = customerSelect.value;
  }

  formState.value.contactId = prop.contractListId;
  await contactContracSave(formState.value).then((res) => {
    if (res.data.success) {
      message.success('添加合同成功');
      addVisible.value = false;
      resetFormState();
      getContractList();
    }
  });
};
const delEdit = () => {
  Object.keys(editableData).forEach((key) => {
    delete editableData[key];
  });
};
const handleClose = (e) => {
  delEdit();
  emits('handleQuery');
};
const handleClose1 = (e) => {
  addVisible.value = false;
  resetFormState();
};
const getContractList = async (reset) => {
  // const searchParams = new URLSearchParams(queryParam.value);
  // console.log(searchParams);
  if (reset) {
    queryParam.value.pageSize = 10;
    queryParam.value.pageNum = 1;
  }
  await contactContractList(queryParam.value).then((res) => {
    if (res.data.success) {
      dataSource.value = res.data.data;
      total.value = res.data.total;
    }
  });
};

const getContactGetEnum = async () => {
  await contactGetEnum('ContractStatus').then((res) => {
    if (res.data.success) {
      contractStatusList.value = res.data.data;
    }
  });
};
const getContactCustomerList = (queryKey) => {
  let param;
  if (queryKey) {
    param = {
      custNameOrCode: queryKey,
    };
  }
  contactCustomerList(param).then((res) => {
    if (res.data.success) {
      const map = {};
      customerList.value = res.data.data.map((item) => {
        map[item.customerCode] = item;
        return { value: item.customerCode, label: item.customerName };
      });
      customerMap.value = map;
    }
  });
};
const addContract = () => {
  addVisible.value = true;
  // 清空编辑选项
  delEdit();
  // 初始化新增合同数据
  resetFormState();
};
// 删除合同
const getcontactContractDel = async (id) => {
  await contactContractDel(id).then((res) => {
    if (res.data.success) {
      message.success('删除合同成功');
      getContractList();
    }
  });
};
// 终止合同
const getcontactContractDischarge = async (id) => {
  await contactContractDischarge(id).then((res) => {
    if (res.data.success) {
      message.success('终止合同成功');
      getContractList();
    }
  });
};

// 监听props.contractListId的变化，重新获取
watch(
  () => prop.contractListId,
  (newVal) => {
    if (newVal) {
      queryParam.value.contactId = newVal;
      getContractList(true);
    }
  }
);

// 在组件挂载时获取用户详情
onMounted(() => {
  if (prop.contractListId) {
    queryParam.value.contactId = prop.contractListId;
    getContractList(true);
  }
  getContactCustomerList();
  getContactGetEnum();
});
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
